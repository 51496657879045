import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  public fName: string;
  public lName: string;
  public email: string;
  public phone: string;
  public inquiry: string;

  constructor() {
    this.fName = '';
    this.lName = '';
    this.email = '';
    this.phone = '';
    this.inquiry = '';
  }

  ngOnInit(): void {
   
    window.scroll(0, 0);
  }

  public sendContactInquiry() {
    console.log(this.fName + ' - ' + this.lName + ' - ' + this.email + ' - ' + this.phone + ' - ' + this.inquiry);
  }
}

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutComponent } from './pages/about/about.component';
import { AgentsBrokersComponent } from './pages/agents-brokers/agents-brokers.component';
import { CareersComponent } from './pages/careers/careers.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './components/footer/footer.component';

//primeng
import { CalendarModule } from 'primeng/calendar';
import { MenubarModule } from 'primeng/menubar';
import { MenuModule } from 'primeng/menu';
import { SlideMenuModule } from 'primeng/slidemenu';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';

import { HttpClientModule } from '@angular/common/http';

//my components
import { HomePageHeaderComponent } from './pages/home/home-page-header/home-page-header.component';
import { HomeProductsComponent } from './pages/home/home-products/home-products.component';

//my services
import { HomeServicesComponent } from './pages/home/home-services/home-services.component';
import { ProspectService } from './services/prospect-service';

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AgentsBrokersComponent,
    CareersComponent,
    ContactComponent,
    HomeComponent,
    FooterComponent,
    HomePageHeaderComponent,
    HomeProductsComponent,
    HomeServicesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CalendarModule,
    MenubarModule,
    MenuModule,
    SlideMenuModule,
    CardModule,
    ButtonModule,
    DropdownModule
  ],
  providers: [ProspectService],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="container-fluid" style="background-image:url('../../../assets/img/general/landscape.jpg'); background-size:1900px; height:500px;">
  <div class="container  pt-2 pb-2">
    <p-card styleClass="p-card-shadow">
      <div class="row">
        <div class="col">
          <h1 style="text-align:center;">Careers</h1>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <h2 style="text-align:center;">Please send you resume to Info@worldClassCoverage.com</h2>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <p>Some of the available positions are:</p>
          <ul>
            <li><h4>CSR</h4></li>
            <li><h4>Sales</h4></li>
            <li><h4>Receptionist</h4></li>
          </ul>
        </div>
      </div>

      <p>World Class Coverage is an equal opporunity employer. </p>
    </p-card>
  </div>
</div>

<div class="gradient-page-header">

  <!--<div class="row product-holder">
    <div class="col-md-10 col-lg-4 ml-auto mr-auto" align="center">
      <div class=" container">
        <h1 class="title" style=" color: white;">Our Products</h1>
        <p style="color:white;">We  provide a side variety of cover for the product you need. We offer both personal and commercial. If you need a cover not listed, Please contact us and we'll fidn the proper coverage for you. </p>
      </div>
    </div>
  </div>-->
  <!--<div class="row product-holder">
    <div class="col-md-10 col-lg-4 ml-auto mr-auto" align="center">
      <div class=" container">
        <h2 class="title" style="color:white;">Personal Home</h2>
        <p style="color:white;">Primary home, secondary home, rental property, renters, flood & mobile home</p>

        <h2 class="title" style="color:white;">Personal Auto</h2>
        <p style="color:white;">Personal auto, motocycles, RV & Collector Car  </p>

        <h2 class="title" style="color:white;">Personal Marine</h2>
        <p style="color:white;">Boats, watercraft, snowmobile </p>

        <h2 class="title" style="color:white;">Personal Umbrella</h2>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 image-container">
      <img class="products-images" src="../../../../assets/img/products/collage-1.jpg" style="width:600px; height:600px;" />
    </div>
  </div>-->
  <!--<div class="row  product-holder">
    <div class="col-md-10 col-lg-4 ml-auto mr-auto" align="center">
      <div class=" container">
        <h2 class="title" style="color:white;">Commercial</h2>
        <p style="color:white;">Commercial Auto</p>
        <p style="color:white;">Commercial Property</p>
        <p style="color:white;">Commercial Trucking</p>
        <p style="color:white;">Commercial General Liability</p>
        <p style="color:white;">Commercial Umbrella</p>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 image-container">
      <img class="products-images" src="../../../../assets/img/products/house.jpg" style="width:600px; height:500px;" />
    </div>
  </div>-->
  <!--<div class="row product-holder">
    <div class="col-md-10 col-lg-4 ml-auto mr-auto" align="center">
      <div class=" container">
        <h2 style="color:white;">Business</h2>
        <p style="color:white;">Business Interruption Insurance</p>
        <p style="color:white;">Business Owners Package Insurance</p>
        <p style="color:white;">Builder's Risk</p>
        <p style="color:white;">Contractor's General Liability</p>
        <p style="color:white;">Employment Practice liability</p>
        <p style="color:white;">Licor Liability</p>
        <p style="color:white;">Professional Liability (E&O)</p>
        <p style="color:white;">Special Event Insurance</p>
        <p style="color:white;">Surety Bond</p>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 image-container">
      <img class="products-images" src="../../../../assets/img/products/handful-of-dirt.jpg" style="width:600px; height:500px;" />
    </div>
  </div>-->
  <!--Personal-->
  <div style=" min-height: 1000px; padding-top:200px;">
    <div class="container">
      <div class="row">
        <div class="ml-auto mr-auto">
          <h3 class="title text-center h2-title">Personal Insurance</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-home"></i>
            </div>
            <h4 class="title h4-title">Home</h4>
            <p> Primary home, secondary home, rental property, renters, flood & mobile home</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-car"></i>
            </div>
            <h4 class="title h4-title">Auto</h4>
            <p> Personal auto, motocycles, RV & Collector Car</p>
          </div>
        </div>
      </div>

      <div class="row ">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-anchor"></i>
            </div>
            <h4 class="title h4-title">Marine</h4>
            <p> Boats, watercraft, snowmobile</p>
          </div>
        </div>
        <div class="col-md-6 col-css " style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-umbrella"></i>
            </div>
            <h4 class="title h4-title">Umbrella</h4>
            <p>If your total value of your assets are greater than the limits of your auto or homeowner's liability</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Commercial-->
  <div class="commercial-section" style="padding-top:100px;">
    <div class="container">
      <div class="row">
        <div class="ml-auto mr-auto">
          <h3 class="title text-center h2-title">Commercial Insurance</h3>
        </div>
      </div>

      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-truck-pickup"></i>
            </div>
            <h4 class="title h4-title">Commercial Auto</h4>
            <p>For companies that use vehicles for business purposes</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-warehouse"></i>
            </div>
            <h4 class="title h4-title">Commercial Property</h4>
            <p>Cover if you own or rent an office or workspace</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-truck"></i>
            </div>
            <h4 class="title h4-title">Commercial Trucking</h4>
            <p>Larger vehicles like dump trucks, cargo vans, and vehicles weighing over 15,000 pounds</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-user-injured"></i>
            </div>
            <h4 class="title h4-title">Commercial General Liability</h4>
            <p>protects your business from financial loss should you be liable for property damage or personal and advertising injury caused by your services, business operations or your employees</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-umbrella-beach"></i>
            </div>
            <h4 class="title h4-title">Commercial Umbrella</h4>
            <p>Protects both your business and the customer in the event of a lawsuit</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-hard-hat"></i>
            </div>
            <h4 class="title h4-title">Builder's Risk</h4>
            <p>Any person or company with a financial interest in the construction project</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-user-tie"></i>
            </div>
            <h4 class="title h4-title">Business Owner Package Insurance (BOP)</h4>
            <p>Most small businesses need general liability insurance and commercial property insurance when they rent or own an office or other commercial space</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-utensils"></i>
            </div>
            <h4 class="title h4-title">Restaurant Insurance</h4>
            <p>This includes business property insurance and liability coverage for your restaurant</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-glass-martini-alt"></i>
            </div>
            <h4 class="title h4-title">Liquor Liability Insurance</h4>
            <p>Liability cover for business that manufactures, sells, serves, or facilitates the use or purchase of alcohol</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-gifts"></i>
            </div>
            <h4 class="title h4-title">Special Event</h4>
            <p>Helps protect you if you're found responsible for property damage or an injury caused during your event,</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-css">
          <div class="info">
            <div class="icon">
              <i class="fas fa-briefcase"></i>
            </div>
            <h4 class="title h4-title">Profesional Liability Insurance (E&O)</h4>
            <p>Protect your company and your bottom line from customer claims of late, incomplete, or unsatisfactory work</p>
          </div>
        </div>
        <div class="col-md-6 col-css" style="border-right:0;">
          <div class="info">
            <div class="icon">
              <i class="fas fa-tools"></i>
            </div>
            <h4 class="title h4-title">Workers' Compensation</h4>
            <p>Employers are required to have this coverage if you have employees but if you dont have employees,</p>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>

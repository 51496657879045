import { Component, OnInit } from '@angular/core';
import { Prospect } from '../../../model/prospect';
import { ProspectService } from '../../../services/prospect-service';

@Component({
  selector: 'app-home-page-header',
  templateUrl: './home-page-header.component.html',
  styleUrls: ['./home-page-header.component.scss']
})
export class HomePageHeaderComponent implements OnInit {

  public prospectForm: Prospect;
  public selectedPolicy: number;
  public policies: PolicyT[];
  public error: string;
  public message: string;

  constructor(private contactUsService: ProspectService) {
    this.error = '';
    this.message = '';
    this.prospectForm = new Prospect();
    this.selectedPolicy = -1;
    this.policies = [
      { code: -1, name: 'Non Selected' },
      { code: 0, name: 'Home' },
      { code: 1, name: 'Auto' }
    ];
  }

  ngOnInit(): void { }

  public saveContact() {
    this.error = '';
    this.message = '';
    if (this.selectedPolicy == -1) {
      this.error = 'Policy type no selected';
    } else {
      this.prospectForm.typeOfQuote = this.selectedPolicy;
     
      this.contactUsService.saveContactForm(this.prospectForm).subscribe(x => {
        let result: any = x;
        if (result.success === true) {
          this.message = 'Thank you for submitting a quote request. We will contact you shortly.';
        }
      });
    }
  }

  public selectedPolicyChanged() {
    switch (this.selectedPolicy) {
      case -1:
        this.error = 'Policy type no selected';
        break;
      case 0: case 1:
        this.error = '';
        this.message = '';
        break;
      default:
    }
  }

}

interface PolicyT {
  name: string;
  code: number;
}

<!--NavBar -->
<nav class="navbar navbar-light theme">
  <a class="navbar-brand" href="#">
    <!--<img src="../../../assets/img/brand/WCC_logo_web.png" width="30" height="30" class="d-inline-block align-top" alt="">-->
    <h3 style="color:white;">World Class Coverage</h3>
  </a>

  <form class="form-inline">
    <label style="color:white; padding-right:50px;">Speak to us 631-358-5984 </label>

    <!--<input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">-->
    <!--<a class="nav-item nav-link" style="color:white;" href="#">Login</a>-->
    <!--DropDown Menu-->
    <!--<p-slideMenu #menu [model]="items" [popup]="true"></p-slideMenu>
    <button #btnqqq icon="pi pi-list" label="Show" (click)="menu.toggle($event)">
      <img src="../../../assets/img/icons/common/menu.png" width="30" height="30" class="d-inline-block align-top" alt="" style="cursor:pointer;" (click)="showMenu()">
    </button>-->
  </form>
</nav>

<!--Content-->
<router-outlet></router-outlet>

<!--TODO-->
<!--<app-chat></app-chat>-->

<hr />
<!--Footer-->
<app-footer></app-footer>

<!--TEMPLATES-->
<!--<p-card header="Speak to a representative" subHeader="test" [style]="{width: '360px'}" styleClass="p-card-shadow">
  <ng-template pTemplate="header">
    <img alt="Card" src="assets/showcase/images/usercard.png">
  </ng-template>
  <p>
    Connect with us simply if you have questions
  </p>
  <h2>Call</h2>
  <p>631-358-5984</p>
  <h2>Email</h2>
  <p>info@WorldClassCoverage.com</p>
  <h2>Use Social Media</h2>
  <span>
    <a href="#"><i class="fa fa-facebook p-2" aria-hidden="true"></i></a>
    <a href="#"><i class="fa fa-instagram p-2" aria-hidden="true"></i></a>
    <a href="#"><i class="fa fa-twitter p-2" aria-hidden="true"></i></a>
  </span>
  <h2>Chat</h2>
  <p-button label="Chat" icon="fas fa-headset" class="block"></p-button>
  <ng-template pTemplate="footer">
    <p-button label="Chat" icon="pi pi-check"></p-button>
    <p-button label="Cancel" icon="pi pi-times" styleClass="p-button-secondary" [style]="{'margin-left': '.5em'}"></p-button>
  </ng-template>
</p-card>-->
<!--END-->
<!--TEMP COMING SOON PAGE-->
<!--<div style="background-color:#1a1aff;">
  <h1 style="text-align:center; margin-top:5%; color:white">World Class Coverage</h1>
  <h3 style="text-align:center; color:white;">Coming Soon!</h3>

  <h3 style="text-align:center; margin-top:200px; color:white;">Contact us today for a free quote!</h3>
  <h3 style="text-align:center; color:white;">Tel: 631-358-5984</h3>

  <h3 style="text-align:center; color:white;">Email: tatiana@worldclasscoverage.com</h3>
</div>-->
<!--<p-menubar [model]="items">
  <ng-template pTemplate="start">
    <img src="../../../../assets/img/brand/wcc_logo_web.png" height="40" class="p-mr-2">
  </ng-template>
  <ng-template pTemplate="end">
    <input type="text" pInputText placeholder="Search">
  </ng-template>
</p-menubar>-->

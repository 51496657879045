<div class="container-fluid">
  <footer class="footer">
    <div class="row" *ngIf="mode==='clientFacing'">
      <div class="col-md-4">
        <h2>Home - Auto - Boat - Renters</h2>
        <ul>
          <li>Product Information</li>
          <li>Coverages</li>
          <li>Report a Claim</li>
          <li>Resources</li>
        </ul>
      </div>
      <div class="col-md-4">
        <h2>Commercial Auto</h2>
        <ul>
          <li>Product Information</li>
          <li>Coverages</li>
          <li>Report a Claim</li>
          <li>Resources</li>
        </ul>
      </div>
      <div class="col-md-4">
        <h2>Workers Compensation</h2>
        <ul>
          <li>Product Information</li>
          <li>Coverages</li>
          <li>Report a Claim</li>
          <li>Resources</li>
        </ul>
      </div>
    </div>

    <div class="row" *ngIf="mode==='clientFacing'">
      <div class="col-md-3">
        <h2 class="text-center"><a [routerLink]="['/careers']">Careers</a></h2>
      </div>
      <div class="col-md-3">
        <h2 class="text-center"><a [routerLink]="['/user/base/login']">Agents</a></h2>
      </div>
      <div class="col-md-3">
        <h2 class="text-center"><a [routerLink]="['/about']">About</a></h2>
      </div>
      <div class="col-md-3">
        <h2 class="text-center"><a [routerLink]="['/contact']">Contact Us</a></h2>
      </div>
    </div>
    <!--</div>
    <div class="container">-->
    <div class="row align-items-center text-center">
      <div class="col-md-12">
        <h2>Stay Connected</h2>
      </div>
    </div>
    <div class="row align-items-center text-center p-2">
      <div class="col-md-12">
        <a href="#">
          <i class="fa fa-facebook p-2" aria-hidden="true" style="font-size:30px;"></i>
        </a>
        <a href="#">
          <i class="fa fa-instagram p-2" aria-hidden="true" style="font-size:30px;"></i>
        </a>
        <a href="#">
          <i class="fa fa-twitter p-2" aria-hidden="true" style="font-size:30px;"></i>
        </a>
      </div>
    </div>
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-md-12">
        <div class="copyright text-center text-muted">
          &copy; {{ test | date: "yyyy" }} <a [routerLink]="['/login']" class="font-weight-bold ml-1" target="_blank">World Class Coverage</a>
        </div>
      </div>
    </div>
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-md-12">
        <div class="copyright text-center text-muted">
          <a [routerLink]="['/home']" class="font-weight-bold ml-1" >Site Map   </a>
        </div>
        <div class="copyright text-center text-muted">
          <a [routerLink]="['/login']" class="font-weight-bold ml-1" >Terms Of Use  </a>
        </div>
        <div class="copyright text-center text-muted">
          <a [routerLink]="['/login']" class="font-weight-bold ml-1" >Privacy &ndash; Security</a>
        </div>
      </div>

    </div>
  </footer>
</div>

<!--Entire container-->
<div class="container-fluid" style="background-image:url('../../../../assets/img/general/beach.jpg'); background-size:1900px; min-height:900px;">
  <!--Container to hold cards centered-->
  <div class="container pt-2 pb-2">
    <div class="row mt-4">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-4 mt-4">
        <!--Cointainer to center content on mobile-->
        <div class="row align-items-center h-100">
          <div class="col-12 mx-auto">
            <div class="card border-primary justify-content-center">
              <div class="card-body" style="height:600px;">
                <h2 class="card-title">Start a quote</h2>
                <form>
                  <div class="row">
                    <div class="col-sm-6 col-md-6">
                      <div class="form-group">
                        <label for="customerFName">First Name *</label>
                        <input type="text" class="form-control" id="customerFName" name="firstName" placeholder="John" [(ngModel)]="prospectForm.firstName">
                      </div>
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <div class="form-group">
                        <label for="customerLName">Last Name *</label>
                        <input type="text" class="form-control" id="customerLName" name="lastName" placeholder="Smith" [(ngModel)]="prospectForm.lastName">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="customerEmail">Email address *</label>
                        <input type="email" class="form-control" id="customerEmail" name="email" placeholder="name@example.com" [(ngModel)]="prospectForm.email">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="customerPhone">Phone</label>
                        <input type="tel" class="form-control" id="customerPhone" name="phone" placeholder="111-333-5566" maxlength="10" [(ngModel)]="prospectForm.phone">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="form-group">
                        <label for="exampleFormControlInput1">Type of Quote *</label>
                        <!--<select class="form-control" [(ngModel)]="contactForm.typeOfQuote">
                          <option *ngFor="let policy of policies" [ngValue]="policy.value">{{policy.name}}</option>
                        </select>-->
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p-dropdown [options]="policies" [(ngModel)]="selectedPolicy" optionLabel="name" name="policyType" optionValue="code" onChange="selectedPolicyChanged()"></p-dropdown>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <p style="height:12px;">
                        <span *ngIf="error !== ''" style="color:red;" class="m-2">{{error}}</span>
                        <span *ngIf="message !== ''" style="color:green;" class="m-2">{{message}}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col">
                      <p-button label="Start" icon="pi pi-check" styleClass="p-button-success" (click)="saveContact()"></p-button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-12 col-md-12  col-lg-6 col-xl-4 mt-4">
        <!--Cointainer to center content on mobile-->
          <div class="row align-items-center ">
            <div class="col-12 mx-auto">
              <div class="card border-primary justify-content-center">
                <div class="card-body"  style="height:600px;">
                  <h2 class="card-title">Speak to a representative</h2>
                  <p class="pt-4">
                    Connect with us
                  </p>
                  <h2 class="pt-4">Call</h2>
                  <p class="pt-4">631-358-5984</p>
                  <h2 class="pt-4">Email</h2>
                  <p class="pt-4">Tatiana@WorldClassCoverage.com</p>
                  <h2 class="pt-4">Use Social Media</h2>
                  <span class="pt-4">
                    <a href="#"><i class="fa fa-facebook p-2" aria-hidden="true"></i></a>
                    <a href="https://www.instagram.com/worldclasscoverage/?utm_medium=copy_link"><i class="fa fa-instagram p-2" aria-hidden="true"></i></a>
                    <!--<a href="#"><i class="fa fa-twitter p-2" aria-hidden="true"></i></a>-->
                  </span>
                </div>
              </div>
            </div>
          </div>
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 col-xl-4 mt-4 ">
        <!--Cointainer to center content on mobile-->
          <div class="row align-items-center">
            <div class="col-12 mx-auto">
              <div class="card h-100 border-primary justify-content-center">
                <div class="card-body"  style="height:600px;">
                  <h2 class="card-title">Lastest News</h2>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item pt-4"><a href="https://www.insurancejournal.com/news/national/2021/09/29/634308.htm" target="_blank">Flood Insurance Program Expires Tomorrow Unless Congress Acts</a></li>
                    <li class="list-group-item pt-4"><a href="https://www.insurancejournal.com/news/national/2021/09/27/633927.htm" target="_blank">Millions Risk Losing Homeowners Insurance in Fire-Ravaged California</a></li>
                    <li class="list-group-item pt-4"><a href="https://www.insurancejournal.com/news/west/2021/09/20/632935.htm" target="_blank">California Issues 1-Year Moratorium on Homeowner Insurance Cancellations And Non-Renewals</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid" style="background-image:url('../../../assets/img/general/bremen-opacity.jpg'); background-size:1900px;">
  <div class="container  pt-2 pb-2">
    <!--<p-card styleClass="p-card-shadow">-->
    <div class="row">
      <div class="col">
        <h1 style="text-align:center; color:white;">About us</h1>
      </div>
    </div>

    <div class="row" style="color:white;">
      <div class="col">
        <h2 style="text-align:center;color:white;">Mission Statement</h2>
        <p>To serve our community with integrity, honestly and with a speedy process within the insurance industry.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
        <p>To never turn down a new customer without the proper due diligence.</p>
      </div>
    </div>

    <div class="row" style="color:white;">
      <div class="col">
        <h2 style="text-align:center;color:white;">Who we are</h2>
        <p>We are a company located in New York that has served since 2010.</p>
        <p>We represent a number of carries because we think this is the best way to serve our customers. We do the shopping for them</p>
        <p>and every scenario doesn't fit all every carrier. Our representatives are trained to fulfill each individuals needs.</p>
        <p>We are licensed in New York State and Florida.</p>
      </div>
    </div>

    <div class="row" style="color:white;">
      <div class="col">
        <h2 style="text-align:center;color:white;">Who we are</h2>
        <p>We are a company located in New York that has served since 2010.</p>
        <p>We represent a number of carries because we think this is the best way to serve our customers. We do the shopping for them</p>
        <p>and every scenario doesn't fit all every carrier. Our representatives are trained to fulfill each individuals needs.</p>
        <p>We are licensed in New York State and Florida.</p>
      </div>
    </div>

    <!--</p-card>-->
  </div>
</div>

import { PolicyType } from "../structural/enum";

export class Prospect {
  public ProspectId: number;
  public firstName: string;
  public lastName: string;
  public email: string;
  public phone: string;
  public typeOfQuote: PolicyType;

  constructor() {
    this.ProspectId = 0;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.phone = '';
    this.typeOfQuote = 0;
  }
}

<div class="gradient-page">
  <div class="container">
    <div class="row" style="padding-top:100px;">
      <div class="ml-auto mr-auto">
        <h2 class="title text-center h2-title">Our Services</h2>
        <p class="m-2" style="color: #9a9a9a; font-size:25px;">Service is what makes us different. We strive to provide excellent customer service with a quick turn around on anything like quotes, binding, proof of insurance and many other services.</p>
      </div>
    </div>

    <div class="row" style="margin-top:100px;">

      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" align="center">
        <i class="fas fa-star"></i>
        <h2>Free Quotes</h2>
        <h3>Start the process online or contact us</h3>
        <p>Our support team is  always avalable for you.</p>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" align="center">
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <h2>Customer Support</h2>
        <h3>Available 7 days a week</h3>
        <p>Our support team is always avalable for your inquiries.</p>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" align="center">
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <h2>Make Payments</h2>
        <h3>Make payments online or contact us</h3>
        <p>Paying your policy is very easy an convenient</p>
      </div>
      <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3" align="center">
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <i class="fas fa-star"></i>
        <h2>Report a claim</h2>
        <h3>Claims can be made 7 days a week</h3>
        <p>We understand that accidents happen when you least expect it. So we have our lines open 7 days a week to report any claims.</p>
      </div>

    </div>
  </div>
</div>

import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { Prospect } from "../model/prospect";

@Injectable()
export class ProspectService {
  constructor(private http: HttpClient, private router: Router) {
  }

  public saveContactForm(prospect: Prospect) {
    //let token: string = DEBUG ? 'DEGUBAndreWizard' : localStorage.getItem('wcct') != null && localStorage.getItem('wcct') != undefined ? localStorage.getItem('wcct') : ''; //'DEGUBAndreWizard';//
    var api: string = environment.apiHost + '/api/v1/';
    let httpOptions = {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
          'Authorization': ''
        }
      ),
      params: new HttpParams()
    };

    
    console.log(api);
    return this.http.post('http://api.worldclasscoverage.com/api/v1/' + 'Prospect/saveProspect', prospect , httpOptions);
  }
}

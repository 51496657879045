import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',//'login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    
  },  
  //{
  //  path: 'user',
  //  component: AuthComponent,
  //  children: [
  //    {
  //      path: 'base',
  //      loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
  //    }
  //  ]
  //},
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

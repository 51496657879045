import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agents-brokers',
  templateUrl: './agents-brokers.component.html',
  styleUrls: ['./agents-brokers.component.css']
})
export class AgentsBrokersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    window.scroll(0, 0);
  }

}

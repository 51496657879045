<div class="container-fluid" style="background-image:url('../../../assets/img/general/waterfall.jpg'); background-size:1900px;">
  <div class="container pt-2 pb-2">
    <div class="row">
      <div class="col-md-12">
        <p-card header="Contact us Today!" subheader="All information obtain is completely confidencial and obligation free." styleClass="p-card-shadow">
          <!--<ng-template pTemplate="header">
            <img alt="Card" src="assets/showcase/images/usercard.png">
          </ng-template>-->
          <form>
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label for="customerFName">First Name *</label>
                  <input type="text" class="form-control" id="customerFName" name="customerFName" placeholder="John" [(ngModel)]="fName">
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="customerLName">Last Name *</label>
                  <input type="text" class="form-control" id="customerLName" name="customerLName" placeholder="Smith" [(ngModel)]="lName">
                </div>
              </div>
            </div>
            <div class="form-group">
              <label for="customerEmail">Email address *</label>
              <input type="email" class="form-control" id="customerEmail" name="customerEmail" placeholder="name@example.com" [(ngModel)]="email">
            </div>
            <div class="form-group">
              <label for="customerPhone">Phone</label>
              <input type="tel" class="form-control" id="customerPhone" name="customerPhone" placeholder="111-333-5566" maxlength="10" [(ngModel)]="phone">
            </div>
            <div class="form-group">
              <label for="exampleFormControlInput1">Inquiry *</label>
              <textarea class="form-control" aria-label="With textarea" id="inquiry" name="inquiry" [(ngModel)]="inquiry"></textarea>
            </div>
          </form>
          <ng-template pTemplate="footer">
            <p-button label="Send" icon="pi pi-check" styleClass="p-button-success" (click)="sendContactInquiry()"></p-button>
          </ng-template>
        </p-card>
      </div>
    </div>
  </div>
</div>
